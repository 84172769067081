import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Opinions from "containers/Feedback/Opinions/Opinions"
import CertificatesAndMemberships from "containers/Feedback/CertificatesAndMemberships/CertificatesAndMemberships"
import SatisfactionSurvey from "containers/Feedback/SatisfactionSurvey/SatisfactionSurvey"

const Feedbacks = () => {
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "O nas",
      link: "/o-nas/firma",
    },
    "Opinie i certyfikaty",
  ]

  const data = useStaticQuery(graphql`
    query FeedbackPageQuery {
      allContentfulStronaOpinieICertyfikaty(limit: 1) {
        nodes {
          description {
            description
          }
          mainPhoto {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 90
              placeholder: NONE
            )
          }
        }
      }
    }
  `)

  const description =
    data.allContentfulStronaOpinieICertyfikaty.nodes[0].description.description
  const mainPhoto = getImage(
    data.allContentfulStronaOpinieICertyfikaty.nodes[0].mainPhoto
  )

  const title = (
    <>
      Opinie, certyfikaty
      <br /> i badanie satysfakcji
    </>
  )
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Opinie i certyfikaty">
      <Header
        title={title}
        mainImage={mainPhoto}
        mainImageAlt={"Opinie i certyfikaty"}
      />
      <Opinions description={description} />
      <CertificatesAndMemberships />
      <SatisfactionSurvey />
    </Layout>
  )
}

export default Feedbacks
