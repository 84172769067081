import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import { graphql, useStaticQuery } from "gatsby"
import { Grid } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "constants/richTextOptions"

const SatisfactionSurvey = () => {
  const data = useStaticQuery(graphql`
    query SatisfactionSurveyQuery {
      allContentfulBadanieSatysfakcji(
        sort: { fields: createdAt, order: DESC }
      ) {
        nodes {
          contentful_id
          description {
            raw
          }
          image {
            gatsbyImageData(backgroundColor: "#e5f5f7", placeholder: NONE)
          }
          title
        }
      }
    }
  `)

  const surveys = data.allContentfulBadanieSatysfakcji.nodes

  return (
    <section>
      <Hr mb={4} mt={10} />
      <Font42 mb={6}>Badanie satysfakcji</Font42>
      <Grid container>
        {surveys.map(({ image, title, description, contentful_id }, i) => {
          const mainImage = getImage(image)
          const isLast = surveys.length === i + 1

          return (
            <React.Fragment key={contentful_id}>
              <Grid
                container
                alignItems="start"
                justifyContent={{ lg: "space-between" }}
                mb={10}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={5}
                  container
                  sx={{
                    marginBottom: { xs: "20px", lg: 0 },
                  }}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  <GatsbyImage
                    image={mainImage}
                    alt="Grafika badania satysfakcji"
                    style={{ maxWidth: 480 }}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={6} container>
                  {title && <Font24 mb={4}>{title}</Font24>}
                  {renderRichText(description, options)}
                </Grid>
              </Grid>
              {!isLast && <Hr style={{ width: "100%" }} mb={8} />}
            </React.Fragment>
          )
        })}
      </Grid>
    </section>
  )
}

export default SatisfactionSurvey
