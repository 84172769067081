import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"
import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import OpinionCard from "../OpinionCard/OpinionCard"

const Opinions = ({ description }) => {
  const data = useStaticQuery(graphql`
    query OpinionQuery {
      allContentfulOpinia {
        nodes {
          name
          surname
          position
          contentful_id
          opinion {
            raw
          }
          avatar {
            gatsbyImageData(
              backgroundColor: "#e5f5f7"
              placeholder: NONE
              quality: 90
            )
          }
        }
      }
    }
  `)

  const opinions = data.allContentfulOpinia.nodes

  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42 mb={6}>Opinie</Font42>
      <Grid container>
        <Grid item xs={12} sm={9} md={6}>
          <Font18 textAlign="justify">{description}</Font18>
        </Grid>
      </Grid>
      <Grid container spacing={4} mt={6} mb={6}>
        {opinions.map(opinion => (
          <Grid
            item
            key={opinion.contentful_id}
            xs={12}
            md={6}
            container
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <OpinionCard {...opinion} />
          </Grid>
        ))}
      </Grid>
    </section>
  )
}

export default Opinions
